require("./bootstrap");
import Inputmask from "inputmask";
import $ from "jquery";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import { cepVerify } from "./address";

$(".owl-carousel").owlCarousel({
    autoWidth: true,
    margin: 0,
    itemWidth: false,
    items: 4,
    loop: true,
    nav: false,
    dots: false,
    autoplay: false,
    autoplayTimeout: 3000,
    center: false,
    margin: 10,
    stagePadding: 0,
});

window.Inputmask = Inputmask;
window.$ = window.jQuery = $;

const cpfCnpj = $(".cpf_cnpj");

function formatRegisterData() {
    cpfCnpj.on("keydown paste", () => {
        const input = cpfCnpj;
        Inputmask(["999.999.999-99", "99.999.999/9999-99"]).mask(input);
    });
    Inputmask("(99) 9 9999-9999").mask(".phone");
}

if (cpfCnpj) {
    formatRegisterData();
}

const cep = $("#cep")
if (cep) {
    cepVerify();
}
